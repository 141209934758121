import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { Column, Row } from '../../elements/Grid';
import { SmTitleLeadership } from '../../elements/Titles';
import { theme } from '../../../tailwind.config.js';

import { LgParagraph, BaseParagraph } from '../../elements/Paragraphs';

import { rem } from 'polished';

const LeadershipImages = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "mike_funderburk.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwo: file(relativePath: { eq: "nic.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      imageThree: file(relativePath: { eq: "steve_wilson.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
    <Row marginBottom="64">
      <Column>
        <SmTitleLeadership dark="true" marginBottom="32">
          Leadership
        </SmTitleLeadership>
      </Column>
      <Column width="1/6">
    </Column>
      <Column width="1/3">
        <Img
          backgroundColor={theme.colors.purple['100']}
          fluid={data.imageOne.childImageSharp.fluid}
          style={{ marginBottom: rem('24px') }}
        />
        <LgParagraph marginBottom="16">Mike Funderburk</LgParagraph>
        <BaseParagraph marginBottom="0">GM</BaseParagraph>
      </Column>
      <Column width="1/3">
        <Img
          backgroundColor={theme.colors.purple['100']}
          fluid={data.imageTwo.childImageSharp.fluid}
          style={{ marginBottom: rem('24px') }}
        />
        <LgParagraph marginBottom="16">Nic Skitt</LgParagraph>
        <BaseParagraph marginBottom="0">COO</BaseParagraph>
      </Column>
      <Column width="1/6">
    </Column>
      </Row>
    </>
  );
};

export default LeadershipImages;
