import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Column, Container, Row } from '../elements/Grid';
import { LgTitle } from '../elements/Titles';
import LeadershipImages from '../components/LeadershipImages';
import TeamImages from '../components/TeamImages';
import TeamPhoto from "../components/TeamPhoto";

const Team = () => (
  <Layout>
    <SEO title="Team" />
    <Container>
      <Row marginBottom="0">
        <Column>
          <LgTitle>Team</LgTitle>
        </Column>
      </Row>
      <Row>
        <Column>
          <TeamPhoto/>
        </Column>
      </Row>
      <LeadershipImages />
      <TeamImages />
    </Container>
  </Layout>
);

export default Team;
