import React from 'react';

import { rem } from 'polished';
import {graphql, useStaticQuery} from "gatsby";
import {theme} from "../../../tailwind.config";
import Img from "gatsby-image";

const TeamPhoto = () => {
  const data = useStaticQuery(graphql`
    query {
      teamPhoto: file(relativePath: { eq: "team_photo.png" }) {
        childImageSharp {
          fluid(maxWidth: 992, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <Img
      backgroundColor={theme.colors.purple['100']}
      fluid={data.teamPhoto.childImageSharp.fluid}
      style={{ marginBottom: rem('24px') }}
    />
  );
};

export default TeamPhoto;
