import React from 'react';
import { Column } from '../../elements/Grid';
import { BaseParagraph, SmParagraph } from '../../elements/Paragraphs';
import { rem } from 'polished';

const TeamPlaceholder = ({ name, position}) => {
    return (
        <Column width="1/4">
        <svg
                width="640px"
                height="495px"
                viewBox="0 0 640 490"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                style={{
                  height: 'auto',
                  marginBottom: rem('24px'),
                  maxWidth: '100%'
                }}
            >
              <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
              >
                <rect
                    id="Rectangle"
                    fill="#e5e5e5"
                    x="0"
                    y="0"
                    width="640"
                    height="495"
                ></rect>
              </g>
            </svg>
          <BaseParagraph marginBottom="8">{name}</BaseParagraph>
          <SmParagraph marginBottom="0">{position}</SmParagraph>
        </Column>   
    )
}



export default TeamPlaceholder