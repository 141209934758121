// team

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { Column, Row } from '../../elements/Grid';
import { SmTitle } from '../../elements/Titles';
import TeamPlaceholder from './TeamPlaceholder';
import TeamImage from './TeamImage';
import { BaseParagraph, SmParagraph } from '../../elements/Paragraphs';
import { theme } from '../../../tailwind.config.js';
import { rem } from 'polished';

const themeColor = theme.colors.purple['100'];

const TeamImages = () => {
  const data = useStaticQuery(graphql`
    query {
      imagePlaceHolder: file(relativePath: { eq: "placeholder_4.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwo: file(relativePath: { eq: "doug.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageThree: file(relativePath: { eq: "christine.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFour: file(relativePath: { eq: "jill.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFive: file(relativePath: { eq: "matt.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSix: file(relativePath: { eq: "kevin.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSeven: file(relativePath: { eq: "rudro.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEight: file(relativePath: { eq: "adelaide.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTen: file(relativePath: { eq: "mark.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEleven: file(relativePath: { eq: "kais.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwelve: file(relativePath: { eq: "bilal.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFourteen: file(relativePath: { eq: "caitie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFifteen: file(relativePath: { eq: "shane.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSixteen: file(relativePath: { eq: "erica.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEighteen: file(relativePath: { eq: "claire_kumagai.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNineteen: file(relativePath: { eq: "selina_winter.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwenty: file(relativePath: { eq: "serah_louis.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwentyOne: file(relativePath: { eq: "Ethan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwentyTwo: file(relativePath: { eq: "sigrid-Small.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwentyThree: file(relativePath: { eq: "niral.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwentyFive: file(relativePath: { eq: "samantha_2023.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwentySix: file(relativePath: { eq: "matthew.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwentySeven: file(relativePath: { eq: "clay.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwentyEight: file(relativePath: { eq: "kris.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      imageTwentyNine: file(relativePath: { eq: "cinzia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageThirty: file(relativePath: { eq: "audrey.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageThirtyOne: file(relativePath: { eq: "martha_2023.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageThirtyThree: file(relativePath: { eq: "mark_england.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageThirtyFour: file(relativePath: { eq: "jon.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageThirtyFive: file(relativePath: { eq: "tariq.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageThirtySix: file(relativePath: { eq: "nancy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageThirtySeven: file(relativePath: { eq: "erfan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageThirtyEight: file(relativePath: { eq: "paul.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageThirtyNine: file(relativePath: { eq: "philip.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageForty: file(relativePath: { eq: "angus.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFortyOne: file(relativePath: { eq: "claire_bragonier.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFortyTwo: file(relativePath: { eq: "brian.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFortyThree: file(relativePath: { eq: "jerry.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFortyFour: file(relativePath: { eq: "vildan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFortyFive: file(relativePath: { eq: "chloe.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFortyEight: file(relativePath: { eq: "reshma.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFortyNine: file(relativePath: { eq: "rose.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFifty: file(relativePath: { eq: "kyle_mah.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFiftyOne: file(relativePath: { eq: "cara.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFiftyTwo: file(relativePath: { eq: "chris.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFiftyFour: file(relativePath: { eq: "Georgia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFiftySix: file(relativePath: { eq: "bronwyn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFiftySeven: file(relativePath: { eq: "cadeem.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFiftyEight: file(relativePath: { eq: "daylen.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFiftyNine: file(relativePath: { eq: "camille.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSixty: file(relativePath: { eq: "dina.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSixtyOne: file(relativePath: { eq: "deepti.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSixtyTwo: file(relativePath: { eq: "isabella.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }      
      imageSixtyFour: file(relativePath: { eq: "james_havers.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSixtyFive: file(relativePath: { eq: "james_battiston.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSixtySix: file(relativePath: { eq: "josh.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSixtyEight: file(relativePath: { eq: "karen.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
        imageSixtyNine: file(relativePath: { eq: "lauren_bird.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSeventyOne: file(relativePath: { eq: "michael.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSeventyTwo: file(relativePath: { eq: "muhammad.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSeventyFour: file(relativePath: { eq: "solon.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSeventyFive: file(relativePath: { eq: "steven.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSeventySix: file(relativePath: { eq: "yaqi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSeventySeven: file(relativePath: { eq: "aaron.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSeventyEight: file(relativePath: { eq: "awais.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSeventyNine: file(relativePath: { eq: "emma.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEighty: file(relativePath: { eq: "evan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEightyOne: file(relativePath: { eq: "kat.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEightyTwo: file(relativePath: { eq: "anuj.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEightyThree: file(relativePath: { eq: "prajwal.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEightyFour: file(relativePath: { eq: "jason.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEightyFive: file(relativePath: { eq: "jenny.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEightySeven: file(relativePath: { eq: "jolie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEightyEight: file(relativePath: { eq: "amy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEightyNine: file(relativePath: { eq: "anna.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNinety: file(relativePath: { eq: "bethan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNinetyOne: file(relativePath: { eq: "daksha.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNinetyTwo: file(relativePath: { eq: "dayle.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNinetyThree: file(relativePath: { eq: "dylan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNinetyFour: file(relativePath: { eq: "em.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNinetyFive: file(relativePath: { eq: "hunter.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNinetySix: file(relativePath: { eq: "leslie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNinetySeven: file(relativePath: { eq: "marie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNinetyEight: file(relativePath: { eq: "raisa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNinetyNine: file(relativePath: { eq: "scott.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundred: file(relativePath: { eq: "romana_king.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredOne: file(relativePath: { eq: "jing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredTwo: file(relativePath: { eq: "allan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredThree: file(relativePath: { eq: "anthony.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredFour: file(relativePath: { eq: "cory.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredFive: file(relativePath: { eq: "deborah.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredSix: file(relativePath: { eq: "jeannyn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredSeven: file(relativePath: { eq: "jonathan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredEight: file(relativePath: { eq: "matt_laforge.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredNine: file(relativePath: { eq: "john_ostrowski.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredTen: file(relativePath: { eq: "ronak.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredEleven: file(relativePath: { eq: "sabina.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredTwelve: file(relativePath: { eq: "tyler.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredThirteen: file(relativePath: { eq: "victoria.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredFourteen: file(relativePath: { eq: "leandro.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredFifteen: file(relativePath: { eq: "shayan.png" }) {
        childImageSharp {
          fluid(maxWidth: 640 quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <Row>
        <Column>
          <SmTitle dark="true" marginBottom="32">
            Editorial
          </SmTitle>
        </Column>
        <TeamImage
            name="James Havers"
            position="VP of Content"
            bgColor={themeColor}
            fluid={data.imageSixtyFour.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
          name="Kevin Hamilton"
          position="Senior Editor, Moneywise.com"
          bgColor={themeColor}
          fluid={data.imageSix.childImageSharp.fluid}
          mb={24}
        ></TeamImage>
        <TeamImage
            name="Romana King"
            position="Senior Editor, Money.ca"
            bgColor={themeColor}
            fluid={data.imageOneHundred.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Brian Pacampara"
            position="Content Strategist"
            bgColor={themeColor}
            fluid={data.imageFortyTwo.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
      </Row>
      <Row>
        <TeamImage
            name="Matt LaForge"
            position="Deputy Editor"
            bgColor={themeColor}
            fluid={data.imageOneHundredEight.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Leslie Kennedy"
            position="Senior Content Editor (SEO)"
            bgColor={themeColor}
            fluid={data.imageNinetySix.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
          name="Samantha Emann"
          position="Senior Associate Editor, Monetization"
          bgColor={themeColor}
          fluid={data.imageTwentyFive.childImageSharp.fluid}
          mb={24}
        ></TeamImage>
        <TeamImage
            name="Sigrid Forberg"
            position="Senior Associate Editor"
            bgColor={themeColor}
            fluid={data.imageTwentyTwo.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
      </Row>
      <Row>
        <TeamImage
            name="Jonathan Linds"
            position="Content Monetization Lead"
            bgColor={themeColor}
            fluid={data.imageOneHundredSeven.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamPlaceholder name="David Saric" position="Associate Editor, Money.ca" />
        <TeamImage
            name="Anthony Vasquez-Peddie"
            position="Associate Editor"
            bgColor={themeColor}
            fluid={data.imageOneHundredThree.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Deborah D'Souza"
            position="Associate Editor"
            bgColor={themeColor}
            fluid={data.imageOneHundredFive.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
      </Row>
      <Row>
        <TeamPlaceholder name="Laura Grande" position="Associate Editor" />
        <TeamImage
            name="Amy Tokic"
            position="Associate Content Editor (SEO)"
            bgColor={themeColor}
            fluid={data.imageEightyEight.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Marie Alcober"
            position="Digital Editor"
            bgColor={themeColor}
            fluid={data.imageNinetySeven.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Jing Pan"
            position="Investing Reporter"
            bgColor={themeColor}
            fluid={data.imageOneHundredOne.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
      </Row>
      <Row>
        <TeamImage
            name="James Battiston"
            position="Staff Reporter"
            bgColor={themeColor}
            fluid={data.imageSixtyFive.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Serah Louis"
            position="Staff Reporter"
            bgColor={themeColor}
            fluid={data.imageTwenty.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Bethan Moorcraft"
            position="Associate Content Strategist, Freelance"
            bgColor={themeColor}
            fluid={data.imageNinety.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Sabina Wex"
            position="Staff Reporter"
            bgColor={themeColor}
            fluid={data.imageOneHundredEleven.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
      </Row>
      <Row marginBottom="144">
        <TeamImage
            name="Em Norton"
            position="Staff Writer"
            bgColor={themeColor}
            fluid={data.imageNinetyFour.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Victoria Vesovski"
            position="Staff Writer"
            bgColor={themeColor}
            fluid={data.imageOneHundredThirteen.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
      </Row>

      <Row>
        <Column>
          <SmTitle dark="true" marginBottom="32">
            Marketing
          </SmTitle>
        </Column>
        <TeamImage
          name="Kris Bruynson"
          position="Vice President of Marketing"
          bgColor={themeColor}
          fluid={data.imageTwentyEight.childImageSharp.fluid}
          mb={24}
        ></TeamImage>
        <TeamImage
            name="Scott Birke"
            position="Director of SEO"
            bgColor={themeColor}
            fluid={data.imageNinetyNine.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamPlaceholder name="Louise Vinciguerra" position="Manager, SEO Content" />
        <TeamPlaceholder name="Shruti Nair" position="Manager, Backlinks & PR" />
      </Row>
      <Row>
        <TeamImage
            name="Tyler Wade"
            position="SEO Content Strategist"
            bgColor={themeColor}
            fluid={data.imageOneHundredTwelve.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Cory Santos"
            position="SEO Content Strategist"
            bgColor={themeColor}
            fluid={data.imageOneHundredFour.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamPlaceholder name="Lubna Umar" position="SEO Content Strategist" />
        <TeamImage
            name="Deepti Gurav"
            position="SEO Specialist"
            bgColor={themeColor}
            fluid={data.imageSixtyOne.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
      </Row>
      <Row>
        <TeamImage
            name="Steven Twigg"
            position="Art Director"
            bgColor={themeColor}
            fluid={data.imageSeventyFive.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Shayan Amiri"
            position="Social Content Creator"
            bgColor={themeColor}
            fluid={data.imageOneHundredFifteen.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Shane Murphy"
            position="Manager of Email"
            bgColor={themeColor}
            fluid={data.imageFifteen.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Bronwyn Petry"
            position="Email Marketing Specialist"
            bgColor={themeColor}
            fluid={data.imageFiftySix.childImageSharp.fluid}
            mb={24}
        >
        </TeamImage>
      </Row>
      <Row marginBottom="144">
        <TeamImage
            name="Josh Herenberg"
            position="Digital Marketing Specialist"
            bgColor={themeColor}
            fluid={data.imageSixtySix.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
      </Row>

      <Row>
        <Column>
          <SmTitle dark="true" marginBottom="32">
            Partnerships
          </SmTitle>
        </Column>
        <TeamImage
          name="Hunter Kern"
          position="Vice President, Partnerships"
          bgColor={themeColor}
          fluid={data.imageNinetyFive.childImageSharp.fluid}
          mb={24}
        ></TeamImage>
        <TeamImage
          name="Angus Baldocchi"
          position="Senior Commercial Partnerships Director"
          bgColor={themeColor}
          fluid={data.imageForty.childImageSharp.fluid}
          mb={24}
        ></TeamImage>
        <TeamImage
            name="Jason Lendrum"
            position="Manager, Partnerships"
            bgColor={themeColor}
            fluid={data.imageEightyFour.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Dylan Shiffman"
            position="Account Manager"
            bgColor={themeColor}
            fluid={data.imageNinetyThree.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
      </Row>
      <Row marginBottom="144">
        <TeamPlaceholder name="Frej Bergman" position="Account Manager" />
        <TeamImage
            name="Claire Bragonier"
            position="Revenue Operations Specialist"
            bgColor={themeColor}
            fluid={data.imageFortyOne.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Evan Gowans"
            position="Partnership Analyst"
            bgColor={themeColor}
            fluid={data.imageEighty.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Jeannyn Gomez"
            position="Content Management Assistant"
            bgColor={themeColor}
            fluid={data.imageOneHundredSix.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
      </Row>

      <Row marginBottom="144">
        <Column>
          <SmTitle dark="true" marginBottom="32">
            Product
          </SmTitle>
        </Column>
        <TeamPlaceholder name="Danyal Aslam" position="VP of Product" />
        <TeamImage
            name="Allan Guzman"
            position="Director of Best Cards and Product"
            bgColor={themeColor}
            fluid={data.imageOneHundredTwo.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="John Ostrowski"
            position="Director of Product"
            bgColor={themeColor}
            fluid={data.imageOneHundredNine.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Karen Corda"
            position="Conversion Optimization Specialist"
            bgColor={themeColor}
            fluid={data.imageSixtyEight.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
      </Row>

      <Row>
        <Column>
          <SmTitle dark="true" marginBottom="32">
            Engineering
          </SmTitle>
        </Column>
        <TeamImage
            name="Leandro Lemos"
            position="VP of Engineering"
            bgColor={themeColor}
            fluid={data.imageOneHundredFourteen.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
          name="Mark Villaflor"
          position="Developer-Team Lead"
          bgColor={themeColor}
          fluid={data.imageTen.childImageSharp.fluid}
          mb={24}
        ></TeamImage>
        <TeamImage
          name="Tariq Elmughrabi"
          position="Full Stack Developer-Team Lead"
          bgColor={themeColor}
          fluid={data.imageThirtyFive.childImageSharp.fluid}
          mb={24}
        ></TeamImage>
        <TeamImage
          name="Selina Winter"
          position="Backend Developer"
          bgColor={themeColor}
          fluid={data.imageNineteen.childImageSharp.fluid}
          mb={24}
        ></TeamImage>
      </Row>
      <Row>
        <TeamImage
            name="Jerry Goguette"
            position="Backend Developer"
            bgColor={themeColor}
            fluid={data.imageFortyThree.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
          name="Vildan Hakanaj"
          position="Full Stack Developer-Team Lead"
          bgColor={themeColor}
          fluid={data.imageFortyFour.childImageSharp.fluid}
          mb={24}
        ></TeamImage>
        <TeamImage
            name="Ronak Patel"
            position="Full Stack Developer"
            bgColor={themeColor}
            fluid={data.imageOneHundredTen.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Isabella Wang"
            position="Jr Frontend Developer"
            bgColor={themeColor}
            fluid={data.imageSixtyTwo.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
      </Row>
      <Row>
        <TeamImage
            name="Solon Gee"
            position="Jr Frontend Developer"
            bgColor={themeColor}
            fluid={data.imageSeventyFour.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Yaqi Wang"
            position="BI Analyst"
            bgColor={themeColor}
            fluid={data.imageSeventySix.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
          name="Reshma Raju"
          position="Sr Product Delivery Manager"
          bgColor={themeColor}
          fluid={data.imageFortyEight.childImageSharp.fluid}
          mb={24}
        ></TeamImage>
        <TeamImage
            name="Dayle McCool"
            position="QA Analyst"
            bgColor={themeColor}
            fluid={data.imageNinetyTwo.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
      </Row>
      <Row marginBottom="144">
        <TeamPlaceholder name="Devangi Vadhdiya" position="QA Analyst" />
        <TeamPlaceholder name="Navkiran Kaur" position="IT Support Specialist" />
      </Row>

      <Row marginBottom="144">
        <Column>
          <SmTitle dark="true" marginBottom="32">
            Operations & Strategy
          </SmTitle>
        </Column>
        <TeamImage
          name="Rudro Chakrabarti"
          position="Senior Director, Operations"
          bgColor={themeColor}
          fluid={data.imageSeven.childImageSharp.fluid}
          mb={24}
        ></TeamImage>
        <TeamImage
            name="Aaron Young"
            position="Senior Director of Business Development"
            bgColor={themeColor}
            fluid={data.imageSeventySeven.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
      </Row>

      <Row marginBottom="144">
        <Column>
          <SmTitle dark="true" marginBottom="32">
            People & Culture
          </SmTitle>
        </Column>
        <TeamImage
            name="Michael Obnowlenny"
            position="Vice President of People & Culture"
            bgColor={themeColor}
            fluid={data.imageSeventyOne.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Katrina Hopkins"
            position="Administrative Services Manager"
            bgColor={themeColor}
            fluid={data.imageEightyOne.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
            name="Anna Szarzynski"
            position="HR Generalist"
            bgColor={themeColor}
            fluid={data.imageEightyNine.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
      </Row>

      <Row marginBottom="144">
        <Column>
          <SmTitle dark="true" marginBottom="32">
            Finance &amp; Legal
          </SmTitle>
        </Column>
        <TeamImage
          name="Christine Lanthier"
          position="Senior Director of Finance"
          bgColor={themeColor}
          fluid={data.imageThree.childImageSharp.fluid}
          mb={24}
        ></TeamImage>
        <TeamImage
            name="Jolie Huang"
            position="Accounting Manager"
            bgColor={themeColor}
            fluid={data.imageEightySeven.childImageSharp.fluid}
            mb={24}
        ></TeamImage>
        <TeamImage
          name="Martha Binks"
          position="General Counsel"
          bgColor={themeColor}
          fluid={data.imageThirtyOne.childImageSharp.fluid}
          mb={24}
        ></TeamImage>
      </Row>
    </>
  );
};

export default TeamImages;
