import React from 'react';
import { Column } from '../../elements/Grid';
import { BaseParagraph, SmParagraph } from '../../elements/Paragraphs';
import Img from 'gatsby-image';
import { rem } from 'polished';
const TeamImage = ({ name, position, bgColor, fluid, mb}) => {
    return (
        <Column width="1/4">
          <Img
            backgroundColor={bgColor}
            fluid={fluid}
            style={{ marginBottom: rem(mb) }}
          />
          <BaseParagraph marginBottom="8">{name}</BaseParagraph>
          <SmParagraph marginBottom="0">{position}</SmParagraph>
        </Column>   
    )
}



export default TeamImage